<template>
  <v-container class="content">
    <title-header :title="donator.fantasyName" />
    <v-row>
      <v-col cols="12" lg="4">
        <v-card class="d-flex flex-column justify-space-between mb-10">
          <v-card-title class="d-flex justify-space-between align-center pt-10">
            <h2>Informações da Empresa</h2>
            <pencil-btn v-if="userCanEdit" @action="openDialogEditInfo" />
          </v-card-title>
          <v-card-text class="mb-8">
            <!-- type -->
            <v-row class="mb-6 mx-0" align="center" justify="start">
              <v-chip color="situation-2">Pessoa Jurídica</v-chip>
            </v-row>

            <!-- CNPJ -->
            <v-row
              v-if="donator.cnpj"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left
                  >mdi-note-text-outline</v-icon
                >
                <p class="ma-0" v-if="donator.cnpj">
                  {{ donator.cnpj | VMask(masks.cnpj) }}
                </p>
              </div>
            </v-row>

            <!-- email --->
            <v-row
              v-if="donator.email"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-email</v-icon>
                <p class="ma-0">
                  {{ donator.email }}
                </p>
              </div>
            </v-row>

            <!-- phone -->
            <v-row
              v-if="donator.phones && donator.phones.length"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-phone</v-icon>
                <phone-info :phones="donator.phones" color="secondary-text" />
              </div>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-title class="d-flex justify-space-between align-center pt-10">
            <h3>Endereço</h3>
            <pencil-btn v-if="userCanEdit" @action="openDialogEditAddress" />
          </v-card-title>

          <v-card-text class="mb-8">
            <!-- address --->
            <v-row
              v-if="donator.address"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-store</v-icon>
                <address-info
                  :address="donator.address"
                  color="secondary-text"
                />
              </div>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          v-if="donator.agent"
          class="d-flex flex-column justify-space-between"
        >
          <v-card-title class="d-flex justify-space-between align-center pt-10">
            <h3>Representante</h3>
            <pencil-btn v-if="userCanEdit" @action="openDialogEditAgent" />
          </v-card-title>
          <v-card-text class="mb-8">
            <!-- name -->
            <v-row
              v-if="donator.agent.name"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-account</v-icon>
                <p class="ma-0">
                  {{ donator.agent.name }}
                </p>
              </div>
            </v-row>

            <!-- email --->
            <v-row
              v-if="donator.agent.email"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-email</v-icon>
                <p class="ma-0">
                  {{ donator.agent.email }}
                </p>
              </div>
            </v-row>

            <!-- phone -->
            <v-row
              v-if="donator.agent.phones && donator.agent.phones.length"
              class="ma-0 mt-2"
              align="center"
              justify="start"
            >
              <div class="d-flex justify-start align-center">
                <v-icon class="mr-5" size="18" left>mdi-phone</v-icon>
                <phone-info
                  :phones="donator.agent.phones"
                  color="secondary-text"
                />
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="8">
        <request-donations
          v-if="donatorId"
          class="container-request-donations"
          :id="donatorId"
        />
      </v-col>
    </v-row>

    <edit-donator-info-pj-dialog
      v-if="editInfoDialogState.dialog"
      :state="editInfoDialogState"
      :donator="donator"
      @submitForm="handleSubmitEditDonatorInfo"
    />

    <edit-address-dialog
      :state="editAddressDialogState"
      :address="donator.address || {}"
      @submitForm="handleSubmitEditAddress"
    />

    <edit-donator-agent-dialog
      v-if="editAgentDialogState.dialog"
      :state="editAgentDialogState"
      :agent="donator.agent || {}"
      @submitForm="handleSubmitEditAgent"
    />
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import AddressInfo from "@/modules/core/views/components/AddressInfo";
  import EditDonatorAgentDialog from "@/modules/donator/views/components/EditDonatorAgentDialog";
  import EditAddressDialog from "@/modules/core/views/components/EditAddressDialog";
  import EditDonatorInfoPJDialog from "@/modules/donator/views/components/EditDonatorInfoPJDialog";
  import PencilBtn from "@/modules/core/views/components/PencilBtn";
  import PhoneInfo from "@/modules/core/views/components/PhoneInfo";
  import RequestDonations from "@/modules/donator/views/components/RequestDonations";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  import masksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "DetailDonatorPJ",

    components: {
      AddressInfo,
      EditAddressDialog,
      EditDonatorAgentDialog,
      "edit-donator-info-pj-dialog": EditDonatorInfoPJDialog,
      PencilBtn,
      PhoneInfo,
      RequestDonations,
      TitleHeader
    },

    mixins: [masksMixin],

    data: () => ({
      donatorId: null,
      donator: {},
      editInfoDialogState: {
        dialog: false
      },
      editAddressDialogState: {
        dialog: false
      },
      editAgentDialogState: {
        dialog: false
      }
    }),

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin",
        userIsPublicRelationsDirector: "user/getUserIsPublicRelationsDirector",
        userIsManagerOperator: "user/getUserIsManagerOperator",
        userIsRecepcionist: "user/getUserIsRecepcionist"
      }),

      userCanEdit() {
        return (
          this.userIsAdmin ||
          this.userIsPublicRelationsDirector ||
          this.userIsManagerOperator ||
          this.userIsRecepcionist
        );
      }
    },

    async mounted() {
      this.initializeDonatorId();
      await this.getDetailDonator();
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("donator", ["getDetailDonatorPJ", "updateDonatorPJ"]),

      initializeDonatorId() {
        this.donatorId = parseInt(this.$route.params.id, 10);
      },

      async getDetailDonator() {
        try {
          const { data } = await this.getDetailDonatorPJ(this.donatorId);
          this.donator = { ...data };
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });

          this.$router.push({ name: "donators" });
        }
      },

      openDialogEditInfo() {
        this.editInfoDialogState.dialog = true;
      },

      closeDialogEditInfo() {
        this.editInfoDialogState.dialog = false;
      },

      openDialogEditAddress() {
        this.editAddressDialogState.dialog = true;
      },

      closeDialogEditAddress() {
        this.editAddressDialogState.dialog = false;
      },

      openDialogEditAgent() {
        this.editAgentDialogState.dialog = true;
      },

      closeDialogEditAgent() {
        this.editAgentDialogState.dialog = false;
      },

      async handleUpdateDonator(data, successMessage, fnHandleSuccess) {
        try {
          await this.updateDonatorPJ({
            id: this.donatorId,
            data
          });

          if (fnHandleSuccess) {
            fnHandleSuccess();
          }

          this.toggleSnackbar({
            text: successMessage,
            type: "success"
          });

          await this.getDetailDonator();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async handleSubmitEditDonatorInfo(form) {
        await this.handleUpdateDonator(
          form,
          "Dados atualizados com sucesso",
          this.closeDialogEditInfo
        );
      },

      async handleSubmitEditAddress(address) {
        await this.handleUpdateDonator(
          { address },
          "Endereço atualizado com sucesso",
          this.closeDialogEditAddress
        );
      },

      async handleSubmitEditAgent(agent) {
        await this.handleUpdateDonator(
          { agent },
          "Representante atualizado com sucesso",
          this.closeDialogEditAgent
        );
      }
    }
  };
</script>
<style lang="scss" scoped>
  .container-request-donations {
    min-height: 670px;
  }
</style>
